/* GLOBALNI STYLY */

body {
    text-align: center;
    margin: 0;
    background-color: rgba(68, 39, 5, 0.2);
}

@media (prefers-color-scheme: dark) {
    body {
        color: white;
        background: #222;
    }

    .MuiPaper-root {
        background-color: powderblue !important;
    }
}

p {
    font-size: 0.8em;
    color: darkslategray;
    text-align: left;
    font-style: italic;
}
.root {
    display: flex;
}

.tile {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 5px;
    width: 45px;
    height: 44px;
}

.ram {
    position: absolute;
    top:0;
    left:0;
    width: 45px;
    height: 44px;
    border-bottom: 5px solid white;
}

.tile.active .ram {
    background-color: rgba(50, 255, 0, 0.4);
    border-color: rgba(50, 210, 0, 1);
}

.tile:hover {
    background: rebeccapurple;
    color: white;
}

.tile:hover .ram{
    background-color: rgba(100,100,100,0.3);
    border-color: rgba(200,200,200,0.7);
}
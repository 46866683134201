.root {
    display: flex;
    justify-content: space-between;
}

.tile {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 1px;
    overflow: hidden;
    position: relative;
    padding-bottom: 5px;
}

.ram {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    border-bottom: 5px solid white;
}

.tile.active {
    background: lightsalmon;
}

.tile:hover {
    background: rebeccapurple;
    color: white;
}

.tile:hover .ram {
    background-color: rgba(100, 100, 100, 0.2);
    border-color: rgba(200, 200, 200, 0.7);
}

.tile.active .ram {
    background-color: rgba(50, 255, 0, 0.4);
    border-color: rgba(50, 210, 0, 1);
}

@media (prefers-color-scheme: dark) {
    .tile {
        color: white;
        background: #444;
    }

    .tile.active {
        background-color: coral;
        font-weight: bold;
    }
}

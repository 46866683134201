.stavby {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.stavby img {
    padding: 6px;
    opacity: 0.3;
    margin: 2px 0;
    /*width: 33px;*/
}

.stavby img:hover {
    background-color: lightseagreen;
    cursor: pointer;
}

.stavby img.active {
    opacity: 1;
    background-color: rgba(74,200,100,0.6);
}

.stavby strong {
    width: 100%;
    font-size: 1.8em;
    padding: 0 2px;
    overflow: hidden;
}

.stavby strong small {
    text-indent: 4px;
    font-weight: normal;
    font-size: 0.5em;
}